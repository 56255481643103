import { NEXT_PUBLIC_HEMIDAL_URL as baseUrl } from "@/data/base-url";
import ky from "ky";
import { NextAuthOptions } from "next-auth";
import CredentialsProvider from "next-auth/providers/credentials";
import GithubProvider from "next-auth/providers/github";
import GoogleProvider from "next-auth/providers/google";
import { IS_DEV } from "./env";
import {
  GitHubProviderStrategy,
  GoogleProviderStrategy,
  OAuthUser,
  getOAuthUser,
} from "./oauth.strategy";

const personalEmailProviders = [
  'gmail.com',
  'yahoo.com',
  'outlook.com',
  'hotmail.com',
  'aol.com',
  'icloud.com',
  'protonmail.com',
  'zoho.com',
  'mail.com',
  'live.com',
  'outlook.com',
  'msn.com',
  'outlook.my',
  'outlook.com.my',
  'hotmail.my',
];
class UseBusinessEmaError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "UseBusinessEmai;Error";
  }
}
function isBusinessEmail(email: string) {
  const emailDomain = email.split('@').at(-1)?.trim();

  if (!emailDomain) return false;

  return !personalEmailProviders.includes(emailDomain.toLowerCase());
}

const getAccessToken = async (email: string) => {
  const response = await ky
    .post(`${baseUrl}/user/session`, {
      json: {
        email,
      },
    })
    .json<{
      access_token: string;
    }>();

  return response.access_token;
};
async function loginOrRegister(_user: any) {
  return fetch(baseUrl + "/user/users", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(_user),
  });
}
const getEmail = (email: string) => {
  return ky.post(`${baseUrl}/user/users/findEmail`, {
    json: { email },
    throwHttpErrors: false,
  });
};

// The problems
// 1- Diable registration for new users.
// 2- Auto login users with email and password and invitation token.
export const authOptions: NextAuthOptions = {
  providers: [
    GithubProvider({
      clientId: process.env.GITHUB_ID ?? "",
      clientSecret: process.env.GITHUB_SECRET ?? "",
      // @ts-ignore
      scope: "read:user",
    }),
    GoogleProvider({
      clientId: process.env.GOOGLE_ID ?? "",
      clientSecret: process.env.GOOGLE_SECRET ?? "",
    }),
    CredentialsProvider({
      id: "credentials-invitation",
      name: "Credentials",
      credentials: {
        email: { label: "Email", type: "text", placeholder: "Email" },
        password: { label: "Password", type: "password" },
        token: { label: "Token", type: "text" },
        name: { label: "Name", type: "text" },
      },
      async authorize(credentials) {
        if (!credentials) return null;
        const request = await fetch(`${baseUrl}/invitation/accept`, {
          method: "POST",
          body: JSON.stringify(credentials),
          headers: { "Content-Type": "application/json" },
        });
        const response = await request.json();
        console.log("invitation response", response);
        if (response.email) {
          return {
            email: response.email,
            id: response.id,
            name: response.name,
          };
        }
        return null;
      },
    }),
  ],
  secret: process.env.SECRET,
  session: {
    strategy: "jwt",
  },
  jwt: {
    secret: process.env.SECRET,
  },
  pages: {
    signIn: "/auth", // Displays signin buttons
    signOut: "/",
    newUser: "/auth", // If set, new users will be directed here on first sign in
    error: "/auth",
    // verifyRequest: '/auth/verify-request', // Used for check email page
  },
  callbacks: {
    async signIn({ user, account, profile, credentials }) {

      if (credentials) {
        if ("from" in credentials) {
          return true;
        }
        try {
          const response = await loginOrRegister(user);
          const responseData = (await response.json()) as {
            email: string;
            id: string;
            name: string;
            access_token: string;
          };
          if (response.ok) {
            return responseData;
          } else {
            return false;
          }
        } catch (e) {
          console.error(e);
          return false;
        }
      }

      let _user: OAuthUser | null = null;
      if (account?.provider === "github") {
        _user = getOAuthUser(
          { profile, user, account },
          new GitHubProviderStrategy(),
        );
      } else if (account?.provider === "google") {
        _user = getOAuthUser(
          { profile, user, account },
          new GoogleProviderStrategy(),
        );
      }

      if (!_user) {
        return false;
      }

      const disableRegistration =
        process.env.NEXT_PUBLIC_DISABLE_REGISTERATION === "true";
      const emailResponse = await getEmail(_user.email);
      if (emailResponse.status === 404) {
        const respData = await emailResponse.json();
        const isBusiness = isBusinessEmail(_user.email);

        if (!isBusiness && !IS_DEV) {
          throw new UseBusinessEmaError("To avoid spamming our services, we ask everyone to use business email addresses.");
        }

        // if (disableRegistration) {
        //   console.log("Disable registration for this email", respData);
        //   return "https://cal.com/opencopilot/30min";
        // }
      }

      try {
        const response = await loginOrRegister(_user);
        if (response.ok) {
          const responseData = await response.json();
          return responseData;
        }
      } catch (error) {
        console.error(error);
        return false;
      }
    },
    async session({ session, token }) {
      if (session.user) {
        session.access_token = token.access_token;
        session.user.email = token.email ?? session.user.email;
      }
      return session;
    },

    async jwt({ token, user }) {
      if (user && user.email) {
        const access_token = await getAccessToken(user.email);
        console.log("access token", access_token);
        token.access_token = access_token;
      }
      return token;
    },
  },

  events: {},
  debug: IS_DEV,
};
