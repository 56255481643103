export interface OAuthUser {
  id: string;
  name: string;
  email: string;
  image: string;
  provider: string;
  accessToken: string;
  idToken: string;
  avatar_url?: string;
}

interface OAuthProvider {
  getUser(profile: any): OAuthUser;
}

export class GitHubProviderStrategy implements OAuthProvider {
  getUser(profile: any): OAuthUser {
    return {
      id: profile.user.id,
      name: profile.user.name,
      email: profile.user.email,
      image: profile.user.image,
      provider: "github",
      accessToken: profile.account.access_token,
      avatar_url: profile.profile.avatar_url,
      idToken: "",
    };
  }
}

export class GoogleProviderStrategy implements OAuthProvider {
  getUser(profile: any): OAuthUser {
    return {
      id: profile.profile.sub,
      name: profile.profile.name,
      email: profile.profile.email,
      image: profile.user.image,
      provider: "google",
      accessToken: profile.account.access_token,
      idToken: profile.account.id_token,
    };
  }
}

export function getOAuthUser(profile: any, provider: OAuthProvider): OAuthUser {
  return provider.getUser(profile);
}
