"use client";

import { useRouter } from "@/lib/router-events";
import { useSearchParams as useNextSearchParams, usePathname } from "next/navigation";
import { useEffect } from "react";
type ValueType = string | number | boolean | undefined | null;
export function useSetSearchParams(onChange?: (params: URLSearchParams) => void) {
  const params = useNextSearchParams();
  const router = useRouter();
  const pathname = usePathname();
  useEffect(() => {
    params && onChange?.(params);
  }, [onChange, params]);
  return {
    searchParams: params,
    setSearchParams: (keys: Record<string, ValueType>) => {
      const newParams = params ? new URLSearchParams(params) : new URLSearchParams();
      for (const key in keys) {
        const value = keys[key];
        if (value === undefined) {
          newParams.delete(key);
        } else {
          newParams.set(key, String(value));
        }
      }
      router.replace(pathname + "/?" + newParams.toString(), {
        scroll: false
      });
    }
  };
}