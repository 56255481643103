import axios from "axios";
import { NEXT_PUBLIC_HEMIDAL_URL as baseUrl } from "./base-url";
import { installAuthInterceptor } from "@/lib/consts";

const instance = installAuthInterceptor(
  axios.create({
    baseURL: baseUrl + "/subscriptions",
    headers: {
      "Content-Type": "application/json",
    },
  }),
);

// {{backend_base}}/subscriptions/create-payment-link
export async function createPaymentLink(data: {
  success_url: string;
  cancel_url: string;
}) {
  return await instance.post<{
    checkout_url: string;
  }>(`/create-payment-link`, data);
}
