import { Nullable } from "@/types/utils";
import { AxiosInstance } from "axios";
import { Session, getServerSession } from "next-auth";
import { getSession } from "next-auth/react";
import { IS_SERVER } from "./env";
import { authOptions } from "./auth-options";

export const X_CONSUMER_USERNAME = "X-CONSUMER-USERNAME";

export async function getUserSession() {
  let session: Nullable<Session> = null;
  if (IS_SERVER) {
    session = await getServerSession(authOptions);
  } else {
    session = await getSession();
  }
  return session;
}

export const installAuthInterceptor = (instance: AxiosInstance) => {
  instance.interceptors.request.use(
    async (config) => {
      let session: Nullable<Session> = await getUserSession();
      if (session && session.user) {
        const user = session.user;
        config.headers[X_CONSUMER_USERNAME] = user.email;
        // @ts-ignore
        config.headers["Authorization"] = `Bearer ${session.access_token}`;
      }
      return config;
    },
    (error) => Promise.reject(error),
  );
  return instance;
};
