import { usePathname, useSearchParams } from "next/navigation";
import { Suspense, useEffect } from "react";
import { onComplete } from "./events";
function HandleOnCompleteChild() {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  useEffect(() => onComplete(), [pathname, searchParams]);
  return null;
}
export function HandleOnComplete() {
  return <Suspense data-sentry-element="Suspense" data-sentry-component="HandleOnComplete" data-sentry-source-file="wrapper.tsx">
      <HandleOnCompleteChild data-sentry-element="HandleOnCompleteChild" data-sentry-source-file="wrapper.tsx" />
    </Suspense>;
}